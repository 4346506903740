<template>
  <div class="overview">
    <Banner/>

    <Headline>
      {{ toolsOverview.headLine }}
    </Headline>

    <ContentBox v-bind:headline="toolsOverview.content.box1.headLine" color="whitesmoke">
      <p>
        {{ toolsOverview.content.box1.content[0] }}
        <ul class="arrow-style">
          <li>{{ toolsOverview.content.box1.content[1] }}</li>
          <li>{{ toolsOverview.content.box1.content[2] }}</li>
          <li>{{ toolsOverview.content.box1.content[3] }}</li>
        </ul>

        {{ toolsOverview.content.box1.content[4] }}
      </p>

      <p>
        <b>{{ toolsOverview.content.box1.content[5] }}</b> {{ toolsOverview.content.box1.content[6] }}
      </p>
      <p>
        <b>{{ toolsOverview.content.box1.content[7] }}</b> {{ toolsOverview.content.box1.content[8] }}
      </p>
    </ContentBox>

    <ContentBox v-bind:headline="toolsOverview.content.box2.headLine">
      <p>
        {{ toolsOverview.content.box2.content }}
      </p>
      <p>
        {{ toolsOverview.content.box2.subline }}
      </p>
    </ContentBox>

    <ContentBox v-bind:headline="toolsOverview.content.box3.headLine">
      <p>
        {{ toolsOverview.content.box3.content }}
      </p>
      <p>
        {{ toolsOverview.content.box3.subline }}
      </p>
    </ContentBox>

  </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';
import Banner from '@/components/Banner.vue';

export default {
  name: 'ToolsOverviewPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
    Banner,
  },
  computed: {
    toolsOverview: function() {
      return this.$store.state.data.toolsOverview;
    }
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/toolsOverview.json",
      dir: "toolsOverview"
    });
  },
};
</script>

<style>
.overview {
  background-color: whitesmoke;
  padding-bottom: 50px;
}
</style>
