<template>
    <div class="generic">
      <Headline>CSS Talks</Headline>

      <ContentBox headline="Panels">
        <ul class="arrow-style">
          <li v-for="panel in panelsList" :key="panel.name+panel.date">
            <b>{{panel.name}}</b> ({{panel.organizedBy}}).
            <span v-if="panel.type">{{panel.type}} </span> <i>{{panel.in}}</i>.
            {{panel.institution}}, {{panel.date}}.
          </li>
        </ul>
      </ContentBox>

      <ContentBox v-for="year in [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016]" :key="year" :headline="'Talks '+String(year)">
          <h4 class="eventlist-person-headline" v-if="getTalks(year, 'kasprowicz').length>0" >
            Dawid Kasprowicz
          </h4>
          <ul class="arrow-style" v-if="getTalks(year, 'kasprowicz').length>0">
            <li class="eventlist-entry"
            v-for="talk in getTalks(year, 'kasprowicz')"
            :key="talk.name+talk.date">
              <b>{{talk.name}}</b>. {{talk.type}} <i>{{talk.in}}.</i>
              {{talk.institution}}, {{talk.date}}.
            </li>
          </ul>
          <h4 class="eventlist-person-headline" v-if="getTalks(year, 'wenz').length>0" >
            Daniel Wenz
          </h4>
          <ul class="arrow-style" v-if="getTalks(year, 'wenz').length>0">
            <li class="eventlist-entry"
            v-for="talk in getTalks(year, 'wenz')"
            :key="talk.name+talk.date">
              <b>{{talk.name}}</b>. {{talk.type}} <i>{{talk.in}}.</i>
              {{talk.institution}}, {{talk.date}}.
            </li>
          </ul>
          <h4 class="eventlist-person-headline" v-if="getTalks(year, 'gramelsberger').length>0">
            Gabriele Gramelsberger
          </h4>
          <ul class="arrow-style" v-if="getTalks(year, 'gramelsberger').length>0">
            <li class="eventlist-entry"
            v-for="talk in getTalks(year, 'gramelsberger')"
            :key="talk.name+talk.date">
              <b>{{talk.name}}</b>. {{talk.type}} <i>{{talk.in}}.</i>
              {{talk.institution}}, {{talk.date}}.
            </li>
          </ul>
          <h4 class="eventlist-person-headline" v-if="getTalks(year, 'kraemer').length>0" >
            Carmen Krämer
          </h4>
          <ul class="arrow-style" v-if="getTalks(year, 'kraemer').length>0">
            <li class="eventlist-entry"
            v-for="talk in getTalks(year, 'kraemer')"
            :key="talk.name+talk.date">
              <b>{{talk.name}}</b>. {{talk.type}} <i>{{talk.in}}.</i>
              {{talk.institution}}, {{talk.date}}.
            </li>
          </ul>
      </ContentBox>

    </div>
</template>

<script>
// TODO sort by date
import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'TalksPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    BlueBox,
    Headline,
  },
  computed: {
    talksList() {
      return this.$store.state.data.talks;
    },
    panelsList() {
      return this.$store.state.data.panels;
    },
  },
  methods: {
    getTalks(year, person) {
      return this.talksList.filter(entry =>
        entry.date.includes(String(year)) && entry.by.toLowerCase().includes(person.toLowerCase()));
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/events/talks.json",
      dir: "talks"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/events/panels.json",
      dir: "panels"
    });
  },
};
</script>

<style scoped>
.generic {
  padding-bottom: 50px;
}
.eventlist-person-headline {
  color: rgb(0, 84, 159);
  font-weight: lighter;
  padding-bottom: 0px;
}
.eventlist-entry {
  padding-bottom: 0px;
  margin-bottom: -5px;
}
/* arrow-style globally defined in App.vue */
</style>
