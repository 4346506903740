<template>
    <ContentBox class="projectbox" :headline='headline' color="whitesmoke">
      <slot/>
      <div class="project-images">
        <div v-for="img in images" :key="img.src" class="project-image-wrapper">
          <a :href="img.href" target="_blank" rel="noopener">
            <img class="projectlogo" :src="img.src" :alt="img.alt"/>
          </a>
        </div>
      </div>
    </ContentBox>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';

export default {
  name: 'ProjectBox',
  components: {
    ContentBox,
  },
  props: {
    headline: String,
    images: Array,
  },
  computed: {
    hasImages() {
      if (this.images !== undefined && this.images != null) {
        if (this.imagess.length > 0) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>

.project-row {
  padding-bottom: 30px;
}

.projectbox {
  padding-bottom: 30px;
}

.project-images{
  padding-top: 1em;
  width: 100%;
}

.projectlogo {
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 15px;
  max-height: 100px;
  max-width: 300px;
}

.project-image-wrapper {
  display: inline;
}
</style>
