<template>
    <div class="projects">
      <Banner/>

      <Headline>Funded Research Projects</Headline>

        <ProjectBox v-for="project in externalProjects"
        :headline='project.title'
        :images='project.images'
              :key='project.title'>
          <ul class="arrow-style">
          <li>
            {{project.description}}
          </li>
          <li v-if="project.publicationInfo">
            <span v-html="project.publicationInfo"/>
          </li>
          <li v-if="project.collaborators">
            Collaborators: {{project.collaborators}}.
          </li>
          <li v-if="project.principalInvestigators">
            Principal Investigators: {{project.principalInvestigators}}.
          </li>
          <li>
            Funding Institution: {{project.fundedBy}},
            {{project.startYear}} to {{project.endYear}}
          </li>
        </ul>
        <div v-if="project.link">
          <a :href="project.link" target="_blank" rel="noopener"> <Button :to="null"/></a>
        </div>
        </ProjectBox>

    </div>
</template>

<script>
import ProjectBox from '@/components/ProjectBox.vue';
import Headline from '@/components/Headline.vue';
import Banner from '@/components/Banner.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'ProjectPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    Headline,
    ProjectBox,
    Banner,
    Button,
  },
  computed: {
    externalProjects() {
      return this.$store.state.data.external;
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/projects/external.json",
      dir: "external"
    });
  }
};
</script>

<style scoped>
.projects{
  background-color: whitesmoke;
}
</style>
