<template>
  <b-col cols="12" class="headline">
    <img src="/favicon.png" alt="" class="logo-print"/>
    <h1><slot/></h1>
  </b-col>
</template>

<script>
export default {
  name: 'Headline',
};
</script>

<style scoped>
.headline {
  text-align: center;
  font-size: 30pt;
  color: rgb(0, 84, 159);
  padding-top: 40px;
  padding-bottom: 20px;
  font-weight: lighter;
}

.headline h1{
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.headline * {
  font-weight: lighter;
}

.logo-print {
  margin: 0 auto;
  width: 50px;
  margin-bottom: 66px;
  display: none;
}

@media print {
  .logo-print {
    display: initial;
  }
}
</style>
