<template>
  <b-button class="home-box-button" :to="to">
    Read More
  </b-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    to: [String, Object],
  },
};
</script>

<style scoped>
.home-box-button {
  margin-bottom: 20px;
  border-color:#BDCD00!important;
  background-color:transparent!important;
  color:#BDCD00!important;
  padding: 8px 12px!important;
  font-size:12pt;
  font-weight:600;
  text-transform:uppercase;
  text-align: center;
  margin-left: 0px;
}
.home-box-button:hover {
  background-color:#BDCD00!important;
  border-color:#BDCD00!important;
  color:white!important;
}
.home-box-button:focus{
  outline:none;
}
.home-box-button:active {
  background-color:#F0F3D0!important;
  border-color:#F0F3D0!important;
  color:#00549F;
  outline:none;
}
@media screen and (max-width: 600px) {
  .home-box-button {
    width: 100%;
  }
}
</style>
