<template>
<div class="body">

  <!-- copy & paste from NavBar.vue. For some reason, an import does NOT work. -->
  <b-navbar class="navbar" toggleable="lg" fixed="top">
    <b-navbar-brand href="https://www.theoryofscience.rwth-aachen.de/">
        <img class="navbar-logo" src=".\assets\logos\tst_rwth_logo.png" alt="Chair Logo"/>
    </b-navbar-brand>
    <b-navbar-brand href="https://khk.rwth-aachen.de/">
        <img class="navbar-logo" src="./assets/logos/khk_logo.jpg" alt="KHK Logo"/>
    </b-navbar-brand>

    <b-navbar-toggle target="nav_collapse" />

    <b-collapse is-nav id="nav_collapse">

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <b-nav-item class="navItemDropdown" to="/" right
        v-bind:class="{navItemActive: this.$route.path.toLowerCase() == ('/')}">Home</b-nav-item>

        <b-nav-item-dropdown class="navItemDropdown" text="Team"
        right v-bind:class="{navItemActive: this.$route.path.toLowerCase().includes('/team')}">
          <b-dropdown-item class="dropdownItem dropdownItemFirst" to="/team/overview">
            Overview
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/team/gramelsberger">
            Prof. Dr. Gabriele Gramelsberger
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/team/pantsar">
            Dr. Markus Pantsar
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/team/wenz">
            Dr. Daniel Wenz
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/team/kasprowicz">
            Dr. Dawid Kasprowicz
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/team/venator">
            Thomas Venator
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/team/rohde">
            Gudrun Rohde
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" :to="{name: 'team-assistants'}">
            Student Research Assistants
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem dropdownItemLast" :to="{name: 'team-alumni'}">
            Alumni
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="navItemDropdown" text="Research" right
        v-bind:class="{navItemActive: this.$route.path.toLowerCase().includes('/research')}">
          <b-dropdown-item class="dropdownItem dropdownItemFirst" to="/research/overview">
            Overview
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/research/projects">
            Projects
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem dropdownItemLast" to="/research/publications">
            Publications
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="navItemDropdown" text="Teaching" right
        v-bind:class="{navItemActive: this.$route.path.toLowerCase().includes('/teaching')}">
          <b-dropdown-item class="dropdownItem dropdownItemFirst" to="/teaching/overview">
            Overview
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/teaching/govtec">
            GovTec
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/teaching/philosophy">
            Philosophy
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/teaching/philosophylecturers">
            Lecturers
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem dropdownItemLast" to="/teaching/colloquia">
            Colloquium
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="navItemDropdown" text="Tools" right
        v-bind:class="{navItemActive: this.$route.path.toLowerCase().includes('/tools')}">
          <b-dropdown-item class="dropdownItem dropdownItemFirst dropdownItemLast" to="/tools/overview">
            Overview
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="navItemDropdown" text="Events" right
        v-bind:class="{navItemActive: this.$route.path.toLowerCase().includes('/events')}">
          <b-dropdown-item class="dropdownItem dropdownItemFirst" to="/events/overview">
            Overview
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem" to="/events/lab">
            Lab Events
          </b-dropdown-item>
          <b-dropdown-item class="dropdownItem dropdownItemLast" to="/events/talks">
            Talks
          </b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

   <b-container fluid class="content">
    <router-view @cookieConsentUpdated="cookieConsentUpdated"/>
   </b-container>

  <Footer/>

  <vue-cookie-accept-decline
    :ref="'cookieBar'"
    :elementId="'cookieBar'"
    :debug="false"
    :position="'bottom'"
    :type="'bar'"
    :disableDecline="false"
    :transitionName="'slideFromBottom'"
    :showPostponeButton="true"
    @clicked-accept="cookieConsentGiven"
    @clicked-decline="cookieConsentWithdrawn">

    <!-- Optional -->
    <div slot="postponeContent">
        &times;
    </div>

    <!-- Optional -->
    <div slot="message">
        We would like to use cookies to ensure you get the best experience on our website.
        <router-link :to="{name: 'privacy'}">See our Privacy &amp; Cookie Policy.</router-link>
    </div>

    <!-- Optional -->
    <div slot="declineContent">
       NO, THANKS
    </div>

    <!-- Optional -->
    <div slot="acceptContent">
        ALLOW
    </div>
  </vue-cookie-accept-decline>

</div>
</template>

<script>
// import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'app',
  metaInfo: {
    title: 'RWTH CSS Lab',
    link: [
      { vmid: 'canonical', rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de' },
    ],
  },
  components: {
    // NavBar,
    Footer,
  },
  data() {
    return {
      cookieConsent: false,
    };
  },
  methods: {
    cookieConsentGiven() {
      this.$store.commit('cookieConsentGiven');
      // console.log(this.$store.state.cookieConsent);
    },
    cookieConsentWithdrawn() {
      this.$store.commit('cookieConsentWithdrawn');
      // console.log(this.$store.state.cookieConsent);
    },
    cookieConsentUpdated() {
      this.$refs.cookieBar.removeCookie();
      this.$refs.cookieBar.init();
      this.cookieConsentWithdrawn();
    },
  },
};

</script>

<style>
* {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}




/* Maigrün as text selection highlight color*/
*::selection {
  background: rgb(189,205,0);
}
*::-moz-selection {
  background: rgb(189,205,0);
}
*::-webkit-selection {
  background: rgb(189,205,0);
}

.body {
  overflow-y: hidden;
  margin: 0;
  padding: 0;
}

.content{
    margin-right: 0px !important;
    margin-left: 0px !important;
    /*avoid white gap on the right side of the page when using container and rows. */
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 66px;
}

.arrow-style {
  list-style: none;
  padding-left: 1em;
}

.arrow-style li {
  padding-top: 0px;
}

.arrow-style li:before {
  content: "";
  border-color: transparent rgb(0, 84, 159);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -0.8em;
  top: 1.1em;
  position: relative;
}

@media screen and (min-width: 993px) {
  a.dropdown-item {
    color: white;
    background-color: transparent;
    height: 100%;
    width: 100%;
    padding-top: 0.8rem;
  }
}


a.dropdown-item:hover {
  color: black;
  background-color: transparent;
}

@media print {
  .content {
    margin-top: 0px;
  }
}

</style>

  <!-- copy & paste from NavBar.vue. For some reason, an import does NOT work. -->
  <style>
  .navbar {
    top: 0;
    background-color: rgba(230, 243, 255,0.95); /*super light blue*/
    background-color: rgba(250, 250, 250,0.95); /*lightgrey*/
    background-color: rgba(0, 84, 159,0.95); /*RWTH blue*/
    background-color: rgba(255, 255, 255,0.98); /*white opaque*/
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
  }

  .navbar-logo {
    height: 40px;
    display: inline-block;
  }

  .navItemDropdown{
    color: grey!important;  /*to overwrite font color*/
  }

  .navItemActive > a{
    color: rgb(0, 84, 159)!important;
  }

  .dropdownItem{
    color: white !important;
    background-color: rgb(0, 84, 159)!important;
    height: 50px;
    border-bottom: 0.1px solid whitesmoke!important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
  }

  .dropdownItemLast {
    border-bottom: none!important;
  }

  .dropdownItemFirst::before {
    content: " ";
    position: absolute;
    right: 15px;
    top: -12px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgb(0, 84, 159);
  }

  a.dropdownItem {
    color: rgb(0, 84, 159);
  }

  .dropdownItem:hover{
    color: black!important;
    background-color: rgb(189, 205,0)!important;
  }

  .dropdownItem:hover.dropdownItemFirst::before{
    border-bottom-color: rgb(189, 205,0)!important;
  }

  .dropdownItem::before:hover {
    background-color: rgb(189, 205,0)!important;
  }


  @media screen and (max-width: 992px) {
    .navbar {
      max-height: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling:touch;
    }
    .navItemDropdown{
      background-color: rgb(0, 84, 159);
      border-bottom: 0.1px solid whitesmoke!important;
    }
    .navItemDropdown > a{
      color: white!important;
      margin-left: 10px;
    }
    .dropdownItem{
      font-size: 12pt!important;
      background-color: white!important;
      color: rgb(0, 84, 159)!important;
    }
    .dropdownItemFirst::before {
      left: 15px;
      top: 35px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 9px solid white;
    }

    .dropdownItem:hover{
      color: rgb(0, 84, 159)!important;
      background-color: initial!important;
    }
    .dropdownItem:hover.dropdownItemFirst::before{
      border-bottom-color: white!important;
    }
    .dropdownItem::before:hover {
      background-color: white!important;
    }

    a.dropdownItem {
      color: rgb(0, 84, 159)!important;
    }

  }

  .dropdown-menu {
    /*display: none;*/
    box-shadow: rgba(50,50,50,0.8) 0 2px 2px;
    padding: 0!important;
  }

  .navbar-toggler{
    border: none!important;
  }
  .navbar-toggler:focus{
    outline: none!important;
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(1deg);
    }
  }

  @keyframes rotate {/* Nothing in here */}

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M 5,5 L 25,25 M 25,5 L 5,25'/></svg>")!important;
    transform: rotate(90deg);
    transition: background-image 0.2s ease-in-out, transform 0.4s ease-in-out;
  }
  /*
  .navbar-toggler[aria-expanded="true"]::before {
    content: "close";
  }
  */
  .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")!important;
    transform: rotate(0deg);
    transition: background-image 0.2s ease-in-out, transform 0.4s ease-in-out;
  }

  .cookie__bar__buttons__button--decline {
    background: rgb(0, 84, 159)!important;
  }
  .cookie__bar__buttons__button--decline:hover {
    background: #003461!important;
  }
  .cookie__bar__buttons__button--accept {
    background: rgb(189, 205,0)!important;
  }
  .cookie__bar__buttons__button--accept:hover {
    background: #92A200!important;
  }
  #nprogress .bar {
    background: rgb(0, 84, 159);
  }

  #nprogress .spinner-icon {
    border-top-color: rgb(0, 84, 159);
    border-left-color: rgb(0, 84, 159);
  }
</style>
