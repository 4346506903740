<template>
  <div class="team-overview">
    <Banner/>

    <Headline>
      Team
    </Headline>

    <ContentBox v-for="person in people"
    :key="person.name"
    :headline="person.name+', '+person.title"
    color="whitesmoke">
      <router-link :to="'/team/'+person.ref">
        <img class="person-image"
        :src="require(`@/assets/csslab-web-content/data/employees/${person.img}`)" :alt="person.name+'\'s Portrait'"/>
      </router-link>
      <p>{{person.field}}</p>
      <p>
        <span v-if="person.email">Email: <a :href="'mailto:'+person.email">
          {{person.email}}
        </a> <br/></span>
        <span v-if="person.tel">Tel: <a :href="'tel:'+person.tel">{{person.tel}}</a></span>
    </p>
    <Button :to="'/team/'+person.ref"/>
    </ContentBox>

    <ContentBox headline="Student Research Assistants" color="whitesmoke">
      <p>Find out more about our
        <router-link :to="{ name: 'team-assistants'}">Student Research Assistants</router-link>.
      </p>
    </ContentBox>

  </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';
import Banner from '@/components/Banner.vue';
import Button from '@/components/Button.vue';

import wenz from '@/assets/csslab-web-content/data/employees/wenz.json';
import kasprowicz from '@/assets/csslab-web-content/data/employees/kasprowicz.json';
import kraemer from '@/assets/csslab-web-content/data/employees/kraemer.json';
import schuettler from '@/assets/csslab-web-content/data/employees/schuettler.json';
import boehres from '@/assets/csslab-web-content/data/employees/boehres.json';
import kerksieck from '@/assets/csslab-web-content/data/employees/kerksieck.json';
import heyen from '@/assets/csslab-web-content/data/employees/heyen.json';
import schaffrath from '@/assets/csslab-web-content/data/employees/schaffrath.json';
import scheeren from '@/assets/csslab-web-content/data/employees/scheeren.json';
import dumschat from '@/assets/csslab-web-content/data/employees/dumschat.json';
import pantsar from '@/assets/csslab-web-content/data/employees/pantsar.json';
import maschmann from '@/assets/csslab-web-content/data/employees/maschmann.json';

export default {
  name: 'TeamOverviewPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
    Banner,
    Button,
  },
  computed: {
    getEmployees: function() {
      let employees = [
        this.$store.state.data.gramelsberger,
        this.$store.state.data.kaminski,
        this.$store.state.data.wenz,
        this.$store.state.data.kasprowicz,
        this.$store.state.data.kraemer,
        this.$store.state.data.schuettler,
        this.$store.state.data.boehres,
        this.$store.state.data.kerksieck,
        this.$store.state.data.heyen,
        this.$store.state.data.schaffrath,
        this.$store.state.data.scheeren,
        this.$store.state.data.dumschat,
        this.$store.state.data.maschmann,
        this.$store.state.data.pantsar,
        this.$store.state.data.venator,
        this.$store.state.data.rohde,
      ]
      return employees;
    },
    people: function() {
      return this.getEmployees.filter((person) => {
        const status = String(person.status).toLowerCase();
        // list neither student assistants nor former employees
        return !(status.includes('student assistant') || status.includes('alumni'));
      });
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/gramelsberger.json",
      dir: "gramelsberger"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/wenz.json",
      dir: "wenz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kasprowicz.json",
      dir: "kasprowicz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kraemer.json",
      dir: "kraemer"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kaminski.json",
      dir: "kaminski"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schuettler.json",
      dir: "schuettler"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/boehres.json",
      dir: "boehres"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kerksieck.json",
      dir: "kerksieck"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/heyen.json",
      dir: "heyen"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schaffrath.json",
      dir: "schaffrath"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/scheeren.json",
      dir: "scheeren"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/dumschat.json",
      dir: "dumschat"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/maschmann.json",
      dir: "maschmann"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/venator.json",
      dir: "venator"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/pantsar.json",
      dir: "pantsar"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/rohde.json",
      dir: "rohde"
    });
  },
};
</script>

<style scoped>
.team-overview {
  background-color: whitesmoke;
  padding-bottom: 50px;
}

.person-image {
  display: inline-block;
  /*float: right;*/
  width: 150px;
  height: 150px;
  border-radius: 50%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 650px) {
  .person-image {
    float: initial;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
