<template>
    <div class="generic">
      <Headline>Publications</Headline>

      <ContentBox headline="Book Series & Journals">
        <BookSeriesList/>
      </ContentBox>
      <ContentBox headline="Books & Special Issues">
        <SpecialIssuesList/>
      </ContentBox>

      <ContentBox v-for="(year, index) in yearList" :key="`year-${index}`" :headline="String(year)">
        <PublicationList :over="publicationList(year)"/>
      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';
import PublicationList from '@/components/PublicationList.vue';
import BookSeriesList from '@/components/BookSeriesList.vue';
import SpecialIssuesList from '@/components/SpecialIssues.vue';

const gramelsberger = require('@/assets/csslab-web-content/data/employees/gramelsberger.json');
const wenz = require('@/assets/csslab-web-content/data/employees/wenz.json');
const kasprowicz = require('@/assets/csslab-web-content/data/employees/kasprowicz.json');
const kraemer = require('@/assets/csslab-web-content/data/employees/kraemer.json');
const schuettler = require('@/assets/csslab-web-content/data/employees/schuettler.json');
const boehres = require('@/assets/csslab-web-content/data/employees/boehres.json');
const kerksieck = require('@/assets/csslab-web-content/data/employees/kerksieck.json');
const heyen = require('@/assets/csslab-web-content/data/employees/heyen.json');
const schaffrath = require('@/assets/csslab-web-content/data/employees/schaffrath.json');
const scheeren = require('@/assets/csslab-web-content/data/employees/scheeren.json');
const dumschat = require('@/assets/csslab-web-content/data/employees/dumschat.json');
const maschmann = require('@/assets/csslab-web-content/data/employees/maschmann.json');
const pantsar = require('@/assets/csslab-web-content/data/employees/pantsar.json');

export default {
  name: 'PublicationsPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    BlueBox,
    Headline,
    PublicationList,
    BookSeriesList,
    SpecialIssuesList,
  },
  methods: {
    publicationList(selector) {
      return this.publications.filter(entry => entry.year.includes(selector));
    },
    hasPublications(person) {
      if (person.publications !== undefined && person.publications != null) {
        if (Object.values(person.publications).some(element => element.length > 0)) {
          /* Check if any "child array" of person.publications (books, reviews etc.) has length > 0.
            Object.values: array of all "child arrays" of person.publications: [books, reviews ...]
            some(): true if any element of the array fulfills callback
            callback: check if element has length >0
          */
          return true;
        }
      }
      return false;
    },
    hasBooks(person) {
      if (!this.hasPublications) return false;
      if (person.publications.books !== undefined && person.publications.books != null) {
        if (person.publications.books.length > 0) return true;
      }
      return false;
    },
    hasContributions(person) {
      if (!this.hasPublications) return false;
      if (person.publications.contributions !== undefined
        && person.publications.contributions != null) {
        if (person.publications.contributions.length > 0) return true;
      }
      return false;
    },
    hasReviewed(person) {
      if (!this.hasPublications) return false;
      if (person.publications.reviewed !== undefined
        && person.publications.reviewed != null) {
        if (person.publications.reviewed.length > 0) return true;
      }
      return false;
    },
  },
  computed: {
    getEmployees: function() {
      let employees = [
        this.$store.state.data.gramelsberger,
        this.$store.state.data.wenz,
        this.$store.state.data.kasprowicz,
        this.$store.state.data.kraemer,
        this.$store.state.data.schuettler,
        this.$store.state.data.boehres,
        this.$store.state.data.kerksieck,
        this.$store.state.data.heyen,
        this.$store.state.data.schaffrath,
        this.$store.state.data.scheeren,
        this.$store.state.data.dumschat,
        this.$store.state.data.maschmann,
        this.$store.state.data.pantsar
      ]
      return employees;
    },
    publications() {
      /*
      GOAL: list all empployees publications of categorie 'Books etc',
      'Peer-reviewed' and 'Contributions' in this order, each employee one after another.
      In the next step, filter that array to only include publications of the current
      + previous 2 years.
      */
      const arr = [];
      this.getEmployees.forEach((person) => {
        if (this.hasBooks(person)) arr.push(...person.publications.books);
        if (this.hasReviewed(person)) arr.push(...person.publications.reviewed);
        if (this.hasContributions(person)) arr.push(...person.publications.contributions);
      });
      // remove duplicates based on publiction name, should be unneccessary
      /*
      const noDuplicates = [];
      arr.forEach((publication) => {
        if (!noDuplicates.some(item => (item.name === publication.name))) {
          noDuplicates.push(publication);
        }
      });
      */
      return [...new Set(arr)];
    },
    yearList() {
      // return array of current + last 2 years, needed for vue rendering
      const current = new Date().getFullYear();
      return [current + 1, current, current - 1, current - 2, current -3];
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/gramelsberger.json",
      dir: "gramelsberger"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/wenz.json",
      dir: "wenz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kasprowicz.json",
      dir: "kasprowicz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kraemer.json",
      dir: "kraemer"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schuettler.json",
      dir: "schuettler"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/boehres.json",
      dir: "boehres"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kerksieck.json",
      dir: "kerksieck"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/heyen.json",
      dir: "heyen"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schaffrath.json",
      dir: "schaffrath"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/scheeren.json",
      dir: "scheeren"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/dumschat.json",
      dir: "dumschat"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/maschmann.json",
      dir: "maschmann"
    });
  }
};
</script>

<style scoped>
.generic {
  padding-bottom: 50px;
}
</style>
