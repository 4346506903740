<template>
  <b-col cols="10" lg="8" class="section-headline">
  <h2><slot/></h2>
</b-col>
</template>

<script>
export default {
  name: 'SectionHeadline',
};
</script>

<style>
.section-headline {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.section-headline h2 {
  color: #92A200;
  font-weight: lighter;
}
</style>
