<template>
    <div class="generic">
      <Headline>{{ teachingPhilosophy.headLine }}</Headline>

      <BlueBox v-bind:headline="teachingPhilosophy.content.blueBox.headLine" img="">
        {{ teachingPhilosophy.content.blueBox.content }}
      </BlueBox>

      <ContentBox headline="Summer Term 2024">
          <b-row class="event-row" v-for="entry in teachingEvents('S24')"
                 :key="entry.name + entry.semesterTags">
              <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
              <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
          </b-row>
      </ContentBox>

      <ContentBox headline="Winter Term 2023">
          <b-row class="event-row" v-for="entry in teachingEvents('W23')"
                 :key="entry.name + entry.semesterTags">
              <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
              <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
          </b-row>
      </ContentBox>

      <ContentBox headline="Summer Term 2023">
          <b-row class="event-row" v-for="entry in teachingEvents('S23')"
                 :key="entry.name + entry.semesterTags">
              <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
              <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
          </b-row>
      </ContentBox>

      <ContentBox headline="Winter Term 2022">
          <b-row class="event-row" v-for="entry in teachingEvents('W22')"
                 :key="entry.name + entry.semesterTags">
              <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
              <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
          </b-row>
      </ContentBox>

    <ContentBox headline="Summer Term 2022">
          <b-row class="event-row" v-for="entry in teachingEvents('S22')"
                 :key="entry.name + entry.semesterTags">
              <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
              <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
          </b-row>
      </ContentBox>

    <ContentBox headline="Winter Term 2021">
        <b-row class="event-row" v-for="entry in teachingEvents('W21')"
               :key="entry.name + entry.semesterTags">
            <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
            <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
    </ContentBox>

    <ContentBox headline="Summer Term 2021">
        <b-row class="event-row" v-for="entry in teachingEvents('S21')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

    <ContentBox headline="Winter Term 2020">
        <b-row class="event-row" v-for="entry in teachingEvents('W20')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

      <ContentBox headline="Summer Term 2020">
        <b-row class="event-row" v-for="entry in teachingEvents('S20')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

      <ContentBox headline="Winter Term 2019">
        <b-row class="event-row" v-for="entry in teachingEvents('W19')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

      <ContentBox headline="Summer Term 2019">
        <b-row class="event-row" v-for="entry in teachingEvents('S19')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

      <ContentBox headline="Winter Term 2018/2019">
        <b-row class="event-row" v-for="entry in teachingEvents('W18')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

      <ContentBox headline="Summer Term 2018">
        <b-row class="event-row" v-for="entry in teachingEvents('S18')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

      <ContentBox headline="Winter Term 2017/2018">
        <b-row class="event-row" v-for="entry in teachingEvents('W17')"
        :key="entry.name + entry.semesterTags">
          <b-col md="2" cols="12" class="event-type-column">{{entry.type}}</b-col>
          <b-col md="10" class="event-name-column">{{entry.name}} ({{entry.heldBy}})</b-col>
        </b-row>
      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'PhilosophyPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    BlueBox,
    Headline,
  },
  computed: {
    teachingPhilosophy: function() {
      return this.$store.state.data.teachingPhilosophy;
    }
  },
  methods: {
    teachingEvents: function(selector) {
      return this.$store.state.data.teachingEvents.filter(entry => entry.semesterTags.includes(selector));
    }    
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/teachingEvents.json",
      dir: "teachingEvents"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/teachingPhilosophy.json",
      dir: "teachingPhilosophy"
    });
  },
};
</script>

<style scoped>
.generic {
  padding-bottom: 50px;
}
.event-row {
  margin-left: 0px;
}
.event-name-column {
  padding-bottom: 5px;
  padding-left: 0px;
}
.event-type-column {
  padding-right: 0px;
}
.event-type-column:before {
  content: "";
  border-color: transparent rgb(0, 84, 159);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: inline-block;
  left: -0.5em;
  position: relative;
}
@media screen and (max-width: 768px) {
  .event-name-column {
    padding-bottom: 15px;
    margin-left: 1.5em;
  }
}
</style>
