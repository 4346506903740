<template>
  <footer class="footer">
    <div class="footer-right">
    <img class="footer-logo" src="../assets/logos/rwth_tst_en_rgb_white.png"
    alt="RWTH Chair for Theory of Science Logo" usemap="#logo_map"/>
    <map name="logo_map">
      <area shape="rect" coords="0,0,175,75" href="https://www.theoryofscience.rwth-aachen.de/go/id/qpmz/">
      <area shape="rect" coords="180,0,400,75" href="https://www.rwth-aachen.de/go/id/a/">
    </map>
      <img class="footer-logo-print" src="../assets/logos/rwth_tst_en_rgb.png"
      alt="RWTH Chair for Theory of Science Logo"/>
      <p>&copy; {{new Date().getFullYear()}} RWTH Aachen</p>
    </div>
    <div class="footer-links">
      <ul>
        <li> <router-link to="/contact"> Contact </router-link> </li>
        <li><router-link to="/legal"> Legal </router-link></li>
        <li><router-link to="/privacy"> Privacy </router-link></li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.footer {
  height: 150px;
  width: 100%;
  background-color: rgb(0, 84, 159);
  -webkit-box-shadow: inset 0px 8px 31px -9px rgba(0,0,0,0.3);
  box-shadow: inset 0px 8px 31px -9px rgba(0,0,0,0.3);
}

.footer-links ul {
  margin-left: 0;
  padding-left: 0;
  padding-top: 10px;
}

.footer-links ul li {
  padding-left: 25px;
  padding-top: 15px;
}

.footer-links a {
  text-decoration: none;
  color: white;
  /*font-weight: lighter;*/
}

.footer-links a:hover {
  text-decoration: underline;
  color: white;
}

.footer-right {
  color: white;
  text-align: right;
  float: right;
  max-width: 400px;
  margin-right: 25px;
  margin-top: 20px;
  /*font-weight: lighter;*/
}

.footer-right p {
  margin-top: -15px;
  margin-right: 15px;
  font-size: 10pt;
}
.footer-logo {
  width: 100%;
}
.footer-logo-print {
  width: 100%;
  display: none;
}

@media screen and (max-width: 600px) {
  .footer-right {
    max-width: 250px;
  }
  .footer-right p {
    margin-top: 30px;
  }
}

@media print {
  .footer-links {
    display: none;
  }
  .footer-right {
    float: none;
    margin: 0 auto;
  }
  .footer-logo {
    display: none;
  }
  .footer-logo-print {
    display: initial;
    margin: 0 auto;
  }
}
</style>
