<template>
  <div class="content-color" :style="backgroundStyle">
    <b-row class = "bluebox-row">
      <b-col cols="11" lg="8" class="bluebox">
        <div v-if="img" class="bluebox-image-wrapper">
          <img class="bluebox-image" :src="img" alt=""/>
        </div>
        <div class= "bluebox-text">
          <h3> {{headline}} </h3>
          <p>
            <slot/>
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'BlueBox',
  props: {
    headline: {
      default: '',
      type: String,
    },
    backgroundColor: {
      default: 'whitesmoke',
      type: String,
    },
    img: {
      default: '',
      type: String,
    },
  },
  computed: {
    backgroundStyle() {
      return `background-color: ${this.backgroundColor};`;
    },
  },
};
</script>

<style scoped>

.bluebox-row {
  margin-left: 0px!important;
  margin-right: 0px!important;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 30px;
}

.bluebox {
  background-color: rgb(0, 84, 159);
  -webkit-box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  margin: 0 auto; /*fix this!!*/
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center; /*to center image vertically*/
}

.bluebox h3{
  color: white;
  padding-top: 20px;
  font-weight: lighter;
}

.bluebox p{
  color: white;
  padding-bottom: 3px;
}

.bluebox-image-wrapper {
  float: left;
  margin-right: 30px;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.bluebox-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 992px) {
  .bluebox {
    flex-direction: column;
  }
  .bluebox-image-wrapper {
    margin-bottom: 0px;

  }
}

@media print {
  .bluebox h3{
    color: rgb(0, 84, 159);
  }
  .bluebox p{
    color: black;
  }
}

</style>
