<template>
  <div>
    <h4 class="publication-type-headline" v-if="headline">{{headline}}</h4>
    <ul class="projectlist arrow-style">
      <li v-for="publication in bookSeries" :key="publication.name">
        <p>
          <b>{{publication.name}}</b>
          <span v-if="publication.publisher"> at {{publication.publisher}}</span>
          <span v-if="publication.authors">({{ publication.authors }})</span>
          <span v-if="publication.in_print"> (in print)</span>
          <span v-if="publication.url">
            <a :href="publication.url" target="_blank" rel="noopener">
              Read more
            </a></span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
const bookSeries = require('@/assets/csslab-web-content/data/publications/bookseries.json');

export default {
  name: 'BookSeriesList',
  props: {
    headline: {
      type: String,
      default: '',
    },
    over: Array,
  },
  computed: {
    bookSeries() {
      return bookSeries;
    },
  },
};
</script>

<style scoped>
.projectlist {
  padding-bottom: 20px;
  text-align:left;
}
.projectlist li{
  margin-bottom: -10px;
}
.publication-type-headline {
  color: rgb(0, 84, 159);
  font-weight: lighter;
  margin-bottom: 0px;
}
.arrow-style p {
  margin-bottom: 0px;
}
</style>
