<template>
    <div class="generic">
      <Headline>CSS Lab Events</Headline>

      <SectionHeadline>Future Events</SectionHeadline>

      <ContentBox v-for="event in getFutureEvents()"
      :key="event.name+event.date"
      :headline="event.name">
        <ul class="arrow-style">
          <li v-if="event.organizedBy">{{event.date}}, {{event.type}} ({{event.organizedBy}})</li>
          <li v-else>{{event.date}}, {{event.type}}</li>
          <li v-if="event.description">{{event.description}}</li>
          <li v-if="event.program"><span v-html="event.program"/></li>
          <li>{{event.location}}</li>
          <li v-if="event.links">
            <p v-for="link in event.links" :key="link.href">
              <a target="_blank" :href="link.href">
                {{ link.name||link.href }}
              </a>
            </p>
          </li>
        </ul>
      </ContentBox>

      <SectionHeadline>Past Events</SectionHeadline>

      <ContentBox v-for="event in getPastEvents()"
      :key="event.name+event.date"
      :headline="event.name">
        <ul class="arrow-style">
          <li>{{event.date}}, {{event.type}} ({{event.organizedBy}})</li>
          <li v-if="event.description">{{event.description}}</li>
          <li v-if="event.program"><span v-html="event.program"/></li>
          <li>{{event.location}}</li>
          <li v-if="event.links">
            <p v-for="link in event.links" :key="link.href">
              <a target="_blank" :href="link.href">
                {{ link.name||link.href }}
              </a>
            </p>
          </li>
        </ul>
      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';
import SectionHeadline from '@/components/SectionHeadline.vue';

function processAndCompareDate(dateObj) {
  const date = new Date(dateObj);
  date.setHours(0, 0, 0, 0); // important, JS compares nonsense otherwise
  return date > new Date();
}

export default {
  name: 'CSSLabEventsPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    BlueBox,
    Headline,
    SectionHeadline,
  },
  computed: {
    eventList() {
      return this.$store.state.data.events;
    },
    getBlueBox() {
      return this.$store.state.labEventsBlueBox;
    },
  },
  methods: {
    getFutureEvents() {
      return this.eventList.filter(elem => processAndCompareDate(elem.endDate));
    },
    getPastEvents() {
      return this.eventList.filter(elem => !processAndCompareDate(elem.endDate));
    }
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/events/events.json",
      dir: "events"
    });
  }
};
</script>

<style scoped>
.generic {
  padding-bottom: 50px;
}
/* arrow-style globally defined in App.vue */
</style>
