<template>
  <div class="overview">
    <Banner/>

    <Headline>
      {{ getResearchOverview.headLine }}
    </Headline>

    <ContentBox v-bind:headline="getResearchOverview.content.box1.headLine" color="whitesmoke">
      <p v-for="(part, index) in getResearchOverview.content.box1.content" :key="`part-${index}`">
        {{ part }}
      </p>
    </ContentBox>

    <ContentBox v-bind:headline="getResearchOverview.content.box2.headLine" color="whitesmoke">
      <ul class="arrow-style">
        <li v-for="project in internalProjectsOverview" :key="project.title">
          <b>{{project.title}}</b> ({{project.by}}) -
          {{project.description}} ({{project.type}}, {{project.startYear}} to {{project.endYear}}).
          <span v-if="project.ref">
            <router-link :to="'/team/'+project.ref">Read more.</router-link>
          </span>
          <span v-if="project.url"><a :href="project.url" target="_blank">Read more</a></span>
        </li>
      </ul>
    </ContentBox>

    <ContentBox v-bind:headline="getResearchOverview.content.box3.headLine" color="whitesmoke">
      <ul class="arrow-style">
        <li v-for="project in externalProjectsOverview" :key="project.title">
          <b>{{project.title}}</b> ({{project.fundedBy}}, {{project.startYear}}-{{project.endYear}})
        </li>
      </ul>
      <Button :to="{ name: 'projects'}"/>
    </ContentBox>

    <ContentBox v-bind:headline="getResearchOverview.content.box4.headLine" color="whitesmoke">
      <ul class="arrow-style">
        <li v-for="collaboration in collaborationsOverview" :key="collaboration.title">
          <b>{{collaboration.title}}</b> <span v-if="collaboration.type">
            ({{collaboration.type}})
          </span> - <span v-html="collaboration.description"/>
        </li>
      </ul>
      <!-- <Button :to="{ name: 'collaborations'}"/> -->
    </ContentBox>

    <ContentBox v-bind:headline="getResearchOverview.content.box5.headLine" color="whitesmoke">
      <p>
        {{ getResearchOverview.content.box5.content }}
      </p>
      <!-- <Button :to="{ name: 'preprints'}"/> -->
    </ContentBox>

    <ContentBox v-bind:headline="getResearchOverview.content.box6.headLine" color="whitesmoke">
      <BookSeriesList/>
    </ContentBox>

    <ContentBox v-bind:headline="getResearchOverview.content.box7.headLine" color="whitesmoke">
      <PublicationList :over="publicationsListUpcoming"/>
      <Button :to="{ name: 'publications'}"/>
    </ContentBox>

    <ContentBox v-bind:headline="getResearchOverview.content.box8.headLine" color="whitesmoke">
      <ul class="arrow-style">
        <li v-for="item in khkOverview" :key="item.description">
          <p>{{item.description}}</p>
        </li>
      </ul>
      <a href="https://khk.rwth-aachen.de">
      <Button>Read More</Button>
      </a>
    </ContentBox>

  </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';
import Banner from '@/components/Banner.vue';
import Button from '@/components/Button.vue';
import PublicationList from '@/components/PublicationList.vue';
import BookSeriesList from '@/components/BookSeriesList.vue';
import ProjectPageVue from './ProjectPage.vue';

// const external = require('@/assets/csslab-web-content/data/projects/external.json');
// const internal = require('@/assets/csslab-web-content/data/projects/internal.json');
// const collaborations = require('@/assets/csslab-web-content/data/projects/collaborations.json');

export default {
  name: 'OverviewPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
    Banner,
    PublicationList,
    Button,
    BookSeriesList,
  },
  methods: {
    hasPublications(person) {
      if (person.publications !== undefined && person.publications != null) {
        if (Object.values(person.publications).some(element => element.length > 0)) {
          /* Check if any "child array" of person.publications (books, reviews etc.) has length > 0.
            Object.values: array of all "child arrays" of person.publications: [books, reviews ...]
            some(): true if any element of the array fulfills callback
            callback: check if element has length >0
          */
          return true;
        }
      }
      return false;
    },
    hasBooks(person) {
      if (!this.hasPublications) return false;
      if (person.publications.books !== undefined && person.publications.books != null) {
        if (person.publications.books.length > 0) return true;
      }
      return false;
    },
    hasContributions(person) {
      if (!this.hasPublications) return false;
      if (person.publications.contributions !== undefined
        && person.publications.contributions != null) {
        if (person.publications.contributions.length > 0) return true;
      }
      return false;
    },
    hasReviewed(person) {
      if (!this.hasPublications) return false;
      if (person.publications.reviewed !== undefined
        && person.publications.reviewed != null) {
        if (person.publications.reviewed.length > 0) return true;
      }
      return false;
    },
  },
  computed: {
    getEmployees: function() {
      let employees = [
        this.$store.state.data.gramelsberger,
        this.$store.state.data.wenz,
        this.$store.state.data.kasprowicz,
        this.$store.state.data.kraemer,
        this.$store.state.data.schuettler,
        this.$store.state.data.boehres,
        this.$store.state.data.kerksieck,
        this.$store.state.data.heyen,
        this.$store.state.data.schaffrath,
        this.$store.state.data.scheeren,
        this.$store.state.data.dumschat,
        this.$store.state.data.maschmann
      ]
      return employees;
    },
    getResearchOverview: function() {
      return this.$store.state.data.researchOverview;
    },

    getExternals() {
      return this.$store.state.data.external;
    },
    getInternals() {
      return this.$store.state.data.internal;
    },

    externalProjectsOverview() {
      const currentYear = new Date().getFullYear();
      return this.getExternals.filter(project => project.endYear >= currentYear);
    },
    internalProjectsOverview() {
      const currentYear = new Date().getFullYear();
      return this.getInternals.filter(project => project.endYear >= currentYear);
    },
    khkOverview(){
      return this.$store.state.data.khk;
    },
    collaborationsOverview() {
      return this.$store.state.data.collaborations;
    },
    publications() {
      /*
      GOAL: list all empployees publications of categorie 'Books etc',
      'Peer-reviewed' and 'Contributions' in this order, each employee one after another.
      In the next step, filter that array to only include publications of the current
      + previous year.
      */
      const arr = [];
      this.getEmployees.forEach((person) => {
        if (this.hasBooks(person)) arr.push(...person.publications.books);
        if (this.hasReviewed(person)) arr.push(...person.publications.reviewed);
        if (this.hasContributions(person)) arr.push(...person.publications.contributions);
      });
      // remove duplicates based on publiction name, should be unneccessary
      /*
      const noDuplicates = [];
      arr.forEach((publication) => {
        if (!noDuplicates.some(item => (item.name === publication.name))) {
          noDuplicates.push(publication);
        }
      });
      */
      return arr;
    },
    publicationsListUpcoming() {
      return this.publications.filter(entry => entry.in_print);
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/researchOverview.json",
      dir: "researchOverview"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/gramelsberger.json",
      dir: "gramelsberger"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/wenz.json",
      dir: "wenz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kasprowicz.json",
      dir: "kasprowicz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kraemer.json",
      dir: "kraemer"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schuettler.json",
      dir: "schuettler"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/boehres.json",
      dir: "boehres"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kerksieck.json",
      dir: "kerksieck"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/heyen.json",
      dir: "heyen"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schaffrath.json",
      dir: "schaffrath"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/scheeren.json",
      dir: "scheeren"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/dumschat.json",
      dir: "dumschat"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/maschmann.json",
      dir: "maschmann"
    });

    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/projects/external.json",
      dir: "external"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/projects/internal.json",
      dir: "internal"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/projects/collaborations.json",
      dir: "collaborations"
    });
  },

};
</script>

<style>
.overview {
  background-color: whitesmoke;
  padding-bottom: 50px;
}
</style>
