/* Standard Vue Imports */
import Vue from 'vue';
import Vuex from 'vuex';

/* Standard Vue Registrations */
Vue.use(Vuex);


const home = require('@/assets/csslab-web-content/data/home.json');
const homeBanner = require('@/assets/csslab-web-content/data/homeBanner.json');
const researchOverview = require('@/assets/csslab-web-content/data/researchOverview.json');
const teachingColloquia = require('@/assets/csslab-web-content/data/teachingColloquia.json');
const teachingGovtec = require('@/assets/csslab-web-content/data/teachingGovtec.json');
const teachingOverview = require('@/assets/csslab-web-content/data/teachingOverview.json');
const teachingPhilosophy = require('@/assets/csslab-web-content/data/teachingPhilosophy.json');
const toolsOverview = require('@/assets/csslab-web-content/data/toolsOverview.json');
const teachingLecturers = require('@/assets/csslab-web-content/data/teachingLecturers.json');

const events = require('@/assets/csslab-web-content/data/events/events.json');
const panels = require('@/assets/csslab-web-content/data/events/panels.json');
const talks = require('@/assets/csslab-web-content/data/events/talks.json');

const collaborations = require('@/assets/csslab-web-content/data/projects/collaborations.json');
const external = require('@/assets/csslab-web-content/data/projects/external.json');
const internal = require('@/assets/csslab-web-content/data/projects/internal.json');
const khk = require('@/assets/csslab-web-content/data/projects/khk.json');

const bookseries = require('@/assets/csslab-web-content/data/publications/bookseries.json');

const gramelsberger = require('@/assets/csslab-web-content/data/employees/gramelsberger.json');
const wenz = require('@/assets/csslab-web-content/data/employees/wenz.json');
const kasprowicz = require('@/assets/csslab-web-content/data/employees/kasprowicz.json');
const kraemer = require('@/assets/csslab-web-content/data/employees/kraemer.json');
const schuettler = require('@/assets/csslab-web-content/data/employees/schuettler.json');
const boehres = require('@/assets/csslab-web-content/data/employees/boehres.json');
const kerksieck = require('@/assets/csslab-web-content/data/employees/kerksieck.json');
const schaffrath = require('@/assets/csslab-web-content/data/employees/schaffrath.json');
const scheeren = require('@/assets/csslab-web-content/data/employees/scheeren.json');
const dumschat = require('@/assets/csslab-web-content/data/employees/dumschat.json');
const maschmann = require('@/assets/csslab-web-content/data/employees/maschmann.json');
const heyen = require('@/assets/csslab-web-content/data/employees/heyen.json');
const kaminski = require('@/assets/csslab-web-content/data/employees/kaminski.json');
const venator = require('@/assets/csslab-web-content/data/employees/venator.json');
const pantsar = require('@/assets/csslab-web-content/data/employees/pantsar.json');
const rohde = require('@/assets/csslab-web-content/data/employees/rohde.json');

const teachingEvents = require('@/assets/csslab-web-content/data/teachingEvents.json');
const colloquiaEvents = require('@/assets/csslab-web-content/data/colloquiaEvents.json');

const data = {
  home,
  homeBanner,
  researchOverview,
  teachingColloquia,
  teachingGovtec,
  teachingOverview,
  teachingPhilosophy,
  teachingLecturers,
  toolsOverview,
  
  gramelsberger,
  wenz,
  kasprowicz,
  kraemer,
  schuettler,
  boehres,
  kerksieck,
  schaffrath,
  scheeren,
  dumschat,
  maschmann,
  heyen,
  kaminski,
  venator,
  pantsar,
  rohde,

  events,
  panels,
  talks,
  collaborations,
  external,
  internal,
  khk,

  bookseries,
  teachingEvents,
  colloquiaEvents
};

/* STORE MODULE */
const store = new Vuex.Store({
  state: {
    // Indicates whether or not the user has accepted cookies (globally) on the page
    // Default is 'false', as user has to specifically opt-in
    cookieConsent: false,
    labEventsBlueBox: false,
    talksBlueBox: false,
    data
  },
  mutations: {
    // User has given consent to allow cookies
    cookieConsentGiven(state) {
      state.cookieConsent = true;
    },
    // User has given consent to allow cookies
    cookieConsentWithdrawn(state) {
      state.cookieConsent = false;
    },
    setData(state, {myJson, dir}) {
      state.data[dir] = myJson;
    }
  },
  actions: {
    fetchData({ commit }, data) {

      // switch for correct json url
      let url = data.url;
      let dir = data.dir;

      fetch(`${url}`, {cache: "no-store"})
      .then(function(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        commit('setData', {myJson, dir});
      })
      .catch((error) => {
        console.warn(`Error encountered while fetching ${dir}`);
        console.warn(error);
        console.warn("The data requested where not found, pls contact the web admin! Using fallback data instead.");
      });

      
    }
  },
});

/* MODULE EXPORTS */
export default store;
