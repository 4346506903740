/* Standard Vue Imports + NProgress Loading Indicator */
import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import Meta from 'vue-meta';

/* Component Imports */
import MaintenancePage from './views/Misc/MaintenancePage.vue';
import Error404Page from './views/Misc/Error404Page.vue';
import Home from './views/Home.vue';

import TeamOverviewPage from './views/Team/TeamOverviewPage.vue';
import PersonPage from './views/Team/PersonPage.vue';
import StudentAssistantsPage from './views/Team/StudentAssistantsPage.vue';
import AlumniPage from './views/Team/AlumniPage.vue';

import ProjectPage from './views/Research/ProjectPage.vue';
// import PreprintsPage from './views/Research/PreprintsPage.vue';
import ResearchOverviewPage from './views/Research/ResearchOverviewPage.vue';
// import CollaborationsPage from './views/Research/CollaborationsPage.vue';
import PublicationsPage from './views/Research/PublicationsPage.vue';

import TeachingOverviewPage from './views/Teaching/TeachingOverviewPage.vue';
import GovTecPage from './views/Teaching/GovTecPage.vue';
import PhilosophyPage from './views/Teaching/PhilosophyPage.vue';
import PhilosophyLecturers from './views/Teaching/PhilosophyLecturers.vue'
// import GraduatePage from './views/Teaching/GraduatePage.vue';
import ColloquiaPage from './views/Teaching/ColloquiaPage.vue';

import ToolsOverviewPage from './views/Tools/ToolsOverviewPage.vue';
// import ManualsPage from './views/Tools/ManualsPage.vue';
// import GlossaryPage from './views/Tools/GlossaryPage.vue';

import EventsOverviewPage from './views/Events/EventsOverviewPage.vue';
import CSSLabEventsPage from './views/Events/CSSLabEventsPage.vue';
import TalksPage from './views/Events/TalksPage.vue';

import ContactPage from './views/FooterLinks/ContactPage.vue';
import LegalPage from './views/FooterLinks/LegalPage.vue';
import PrivacyPage from './views/FooterLinks/PrivacyPage.vue';

/* Vue PlugIn Registrations */
Vue.use(NProgress);
Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: {
        metaProp: {
          title: 'Home - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de', vmid: 'canonical' },
          ],
        },
      },
    },

    {
      path: '/team/overview',
      name: 'team-overview',
      component: TeamOverviewPage,
      props: {
        metaProp: {
          title: 'Team - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/overview', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/assistants',
      name: 'team-assistants',
      component: StudentAssistantsPage,
      props: {
        metaProp: {
          title: 'Student Assistants - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/assistants', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/alumni',
      name: 'team-alumni',
      component: AlumniPage,
      props: {
        metaProp: {
          title: 'Alumni - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/alumni', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/gramelsberger',
      name: 'gramelsberger',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Prof. Dr. Gabriele Gramelsberger - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/gramelsberger', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/kaminski',
      name: 'kaminski',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Dr. Andreas Kaminski - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/kaminski', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/kraemer',
      name: 'kraemer',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Dr. Carmen Krämer - RWTH CSS Lab',         
        },
      },
    },
    {
      path: '/team/wenz',
      name: 'wenz',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Dr. Daniel Wenz - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/wenz', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/kasprowicz',
      name: 'kasprowicz',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Dr. Dawid Kasprowicz - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/kasprowicz', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/schuettler',
      name: 'schuettler',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Lisa Schüttler - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/schuettler', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/venator',
      name: 'rowland',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Thomas Venator - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/venator', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/pantsar',
      name: 'pantsar',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Markus Pantsar - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/pantsar', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/rohde',
      name: 'rohde',
      component: PersonPage,
      props: {
        metaProp: {
          title: 'Gudrun Rohde - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/team/rohde', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/team/boehres',
      name: 'boehres',
      component: PersonPage,
      meta: {
        title: 'Lukas Böhres - RWTH CSS Lab',
      },
    },
    {
      path: '/team/kerksieck',
      name: 'kerksieck',
      component: PersonPage,
      meta: {
        title: 'Frederik Kerksieck - RWTH CSS Lab',
      },
    },
    {
      path: '/team/heyen',
      name: 'heyen',
      component: PersonPage,
      meta: {
        title: 'David Heyen - RWTH CSS Lab',
      },
    },
    {
      path: '/team/schaffrath',
      name: 'schaffrath',
      component: PersonPage,
      meta: {
        title: 'Jennifer Schaffrath - RWTH CSS Lab',
      },
    },
    {
      path: '/team/dumschat',
      name: 'dumschat',
      component: PersonPage,
      meta: {
        title: 'Tobias Dumaschat - RWTH CSS Lab',
      },
    },
    {
      path: '/team/maschmann',
      name: 'maschmann',
      component: PersonPage,
      meta: {
        title: 'Daniel Maschmann - RWTH CSS Lab',
      },
    },

    {
      path: '/research/overview',
      name: 'research-overview',
      component: ResearchOverviewPage,
      props: {
        metaProp: {
          title: 'Research - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/research/overview', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/research/projects',
      name: 'projects',
      component: ProjectPage,
      props: {
        metaProp: {
          title: 'Projects - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/research/projects', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/research/collaborations',
      name: 'collaborations',
      component: MaintenancePage,
      props: {
        metaProp: {
          title: 'Collaborations - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/research/collaborations', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/research/publications',
      name: 'publications',
      component: PublicationsPage,
      props: {
        metaProp: {
          title: 'Publications - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/research/publications', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/research/preprints',
      name: 'preprints',
      component: MaintenancePage,
      props: {
        metaProp: {
          title: 'Preprints - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/research/preprints', vmid: 'canonical' },
          ],
        },
      },
    },


    {
      path: '/teaching/overview',
      name: 'teaching-overview',
      component: TeachingOverviewPage,
      props: {
        metaProp: {
          title: 'Teaching - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/teaching/overview', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/teaching/govtec',
      name: 'govtec',
      component: GovTecPage,
      props: {
        metaProp: {
          title: 'MA GovTec - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/teaching/govtec', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/teaching/philosophy',
      name: 'philosophy',
      component: PhilosophyPage,
      props: {
        metaProp: {
          title: 'Teaching in Philosophy - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/teaching/philosophy', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/teaching/philosophylecturers',
      name: 'philosophylecturers',
      component: PhilosophyLecturers,
      props: {
        metaProp: {
          title: 'Lecturers in Philosophy - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/teaching/philosophylecturers', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/teaching/graduate-research',
      name: 'graduate-research',
      component: MaintenancePage,
      props: {
        metaProp: {
          title: 'Graduate Research - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/teaching/graduate-research', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/teaching/colloquia',
      name: 'colloquia',
      component: ColloquiaPage,
      props: {
        metaProp: {
          title: 'Colloquium - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/teaching/colloquia', vmid: 'canonical' },
          ],
        },
      },
    },


    {
      path: '/tools/overview',
      name: 'tools-overview',
      component: ToolsOverviewPage,
      props: {
        metaProp: {
          title: 'Tools - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/tools/overview', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/tools/manuals',
      name: 'manuals',
      component: MaintenancePage,
      props: {
        metaProp: {
          title: 'Manuals - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/tools/manuals', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/tools/glossary',
      name: 'glossary',
      component: MaintenancePage,
      props: {
        metaProp: {
          title: 'Glossary - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/tools/glossary', vmid: 'canonical' },
          ],
        },
      },
    },

    {
      path: '/events/overview',
      name: 'events-overview',
      component: EventsOverviewPage,
      props: {
        metaProp: {
          title: 'Events - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/events/overview', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/events/lab',
      name: 'lab-events',
      component: CSSLabEventsPage,
      props: {
        metaProp: {
          title: 'Lab Events - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/events/lab', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/events/talks',
      name: 'talks',
      component: TalksPage,
      props: {
        metaProp: {
          title: 'Talks & Panels - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/events/talks', vmid: 'canonical' },
          ],
        },
      },
    },


    {
      path: '/contact',
      name: 'contact',
      component: ContactPage,
      props: {
        metaProp: {
          title: 'Conatact - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/contact', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyPage,
      props: {
        metaProp: {
          title: 'Privacy - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/privacy', vmid: 'canonical' },
          ],
        },
      },
    },
    {
      path: '/legal',
      name: 'legal',
      component: LegalPage,
      props: {
        metaProp: {
          title: 'Legal - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: 'https://www.css-lab.rwth-aachen.de/legal', vmid: 'canonical' },
          ],
        },
      },
    },

    {
      path: '*',
      name: '404',
      component: Error404Page,
      props: {
        metaProp: {
          title: 'Error 404 - RWTH CSS Lab',
          link: [
            { rel: 'canonical', href: '', vmid: 'canonical' },
          ],
        },
      },
    },
  ],
});


/* NProgress CONFIGURATION (loading indicator on page change) */
/* timeout of 200 ms added to meke page changes feel more natural */
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  setTimeout(() => {
    next();
  }, 200);
});

router.afterEach(() => {
  NProgress.done();
});

// PASSWORD INPUT. DELETE ME BEFORE ACTUAL PRODUCTION!
// -----------------------------------------
/*
let auth = false;

router.beforeEach((to, from, next) => {
  if (!auth && to.path !== '/') {
    const input = prompt('Enter Password');

    // hash
    let hash = 0;
    if (input.length === 0) return hash;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash &= hash; // Convert to 32bit integer
    }

    if (hash === -1350943574) {
      auth = true;
      next();
    } else {
      alert('Password incorrect');
      next('/');
    }
  } else {
    next();
  }
  return null;
});
*/
// -----------------------------------------

/* MODULE EXPORT */
export default router;
