<template>
  <div class="overview">
    <Banner/>

    <Headline>
      Events
    </Headline>

    <ContentBox headline="CSS Lab Events (Upcoming)">
      <b-row class="event-row" v-for="event in getFutureEvents"
      :key="event.name + event.date">
        <b-col md="2" cols="12" class="event-type-column">{{getMonthYear(event.startDate)}}</b-col>
        <b-col md="10" class="event-name-column">{{event.type}} <b>{{event.name}}</b>
          ({{event.date}})</b-col>
      </b-row>
      <Button :to="{name: 'lab-events'}" style="margin-top: 10px;"/>
    </ContentBox>

    <ContentBox headline="Talks and Panels (upcoming)">
      <ul class="arrow-style">
        <li v-for="event in getFuturePanels" :key="event.name+event.date">
          <p>
            <b>{{event.name}}</b>. {{event.type}} <i>{{event.in}}.</i>
            {{event.institution}}, {{event.date}}.
          </p>
        </li>
        <li v-for="event in getFutureTalks" :key="event.name+event.date">
          <p>
            {{ getEmployeeOfName(event.by).name }}:

            <b>{{event.name}}</b>. {{event.type}} <i>{{event.in}}.</i>
            {{event.institution}}, {{event.date}}.
          </p>
        </li>
      </ul>
      <Button :to="{name: 'talks'}" />
    </ContentBox>

  </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';
import Banner from '@/components/Banner.vue';
import Button from '@/components/Button.vue';

function processAndCompareDate(dateObj) {
  const date = new Date(dateObj);
  date.setHours(0, 0, 0, 0); // important, JS compares nonsense otherwise
  return date > new Date();
}

export default {
  name: 'EventsOverviewPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
    Button,
    Banner,
  },
  methods: {

    getMonthYear(dateObj) {
      const date = new Date(dateObj);
      const month = date.toLocaleString('en-us', { month: 'long' });
      const year = date.getFullYear();
      return `${month} ${year}`;
    },
    getEmployeeOfName(ref) {
      return this.getEmployees.find(function(el) {
        return el.ref == ref;
      })
    },
  },
  computed: {

    getFutureEvents() {
      return this.getEvents.filter(elem => processAndCompareDate(elem.endDate));
    },
    getFutureTalks() {
      return this.getTalks.filter(elem => processAndCompareDate(elem.endDate));
    },
    getFuturePanels() {
      return this.getPanels.filter(elem => processAndCompareDate(elem.endDate));
    },
    getEvents: function() {
      return this.$store.state.data.events;
    },
    getTalks: function() {
      return this.$store.state.data.talks;
    },
    getPanels: function() {
      return this.$store.state.data.panels;
    },
    getEmployees: function() {
      let employees = [
        this.$store.state.data.gramelsberger,
        this.$store.state.data.wenz,
        this.$store.state.data.kasprowicz,
        this.$store.state.data.schuettler,
        this.$store.state.data.boehres,
        this.$store.state.data.kerksieck,
        this.$store.state.data.heyen,
        this.$store.state.data.schaffrath,
        this.$store.state.data.scheeren,
        this.$store.state.data.dumschat,
        this.$store.state.data.maschmann
      ]
      return employees;
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/events/events.json",
      dir: "events"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/events/talks.json",
      dir: "talks"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/events/panels.json",
      dir: "panels"
    });

    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/gramelsberger.json",
      dir: "gramelsberger"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/wenz.json",
      dir: "wenz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kasprowicz.json",
      dir: "kasprowicz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kraemer.json",
      dir: "kraemer"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schuettler.json",
      dir: "schuettler"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/boehres.json",
      dir: "boehres"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kerksieck.json",
      dir: "kerksieck"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/heyen.json",
      dir: "heyen"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schaffrath.json",
      dir: "schaffrath"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/scheeren.json",
      dir: "scheeren"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/dumschat.json",
      dir: "dumschat"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/maschmann.json",
      dir: "maschmann"
    });
  }
};
</script>

<style>
.overview {
  background-color: whitesmoke;
  padding-bottom: 50px;
}
.event-row {
  margin-left: 0px;
}
.event-name-column {
  padding-bottom: 5px;
  padding-left: 0px;
}
.event-type-column {
  padding-right: 0px;
}
.event-type-column:before {
  content: "";
  border-color: transparent rgb(0, 84, 159);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: inline-block;
  left: -0.5em;
  position: relative;
}
@media screen and (max-width: 768px) {
  .event-name-column {
    padding-bottom: 15px;
    margin-left: 1.5em;
  }
}
</style>
