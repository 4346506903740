import { render, staticRenderFns } from "./AlumniPage.vue?vue&type=template&id=fce77796&scoped=true&"
import script from "./AlumniPage.vue?vue&type=script&lang=js&"
export * from "./AlumniPage.vue?vue&type=script&lang=js&"
import style0 from "./AlumniPage.vue?vue&type=style&index=0&id=fce77796&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fce77796",
  null
  
)

export default component.exports