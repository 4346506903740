<template>
  <div>
    <h4 class="publication-type-headline" v-if="headline">{{headline}}</h4>
    <ul class="projectlist arrow-style">
      <li v-for="(publication, i) in over" v-bind:key="`item-${i}`">
        <p>{{publication.authors}} ({{publication.year}}).
          <b>{{publication.name}}</b>.
          <span v-if="publication.in">In:
            <i>{{publication.in}} </i>
            <span v-if="publication.in_author">({{publication.in_author}}) </span>
            <span v-if="publication.volume"> {{publication.volume}}</span>
            <span v-if="publication.pages">, {{publication.pages}}.</span>
            <span v-else>.</span>
          </span>
          <span v-if="publication.city"> {{publication.city}}: </span>
          <span v-if="publication.publisher"> {{publication.publisher}}.</span>
          <span v-if="publication.doi"> DOI: {{publication.doi}}.</span>
          <span v-if="publication.url">
            <a :href="publication.url" target="_blank" rel="noopener">
              open access
            </a>.
          </span>
          <span v-if="publication.in_print"> (in print).</span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PublicationList',
  props: {
    headline: {
      type: String,
      default: '',
    },
    over: Array,
  },
};
</script>

<style scoped>
.projectlist {
  padding-bottom: 20px;
  text-align:left;
}
.projectlist li{
  margin-bottom: -10px;
}
.publication-type-headline {
  color: rgb(0, 84, 159);
  font-weight: lighter;
  margin-bottom: 0px;
}
.arrow-style p {
  margin-bottom: 0px;
}
</style>
