<template>
    <div class="person background-global">
      <Headline>
        {{person.name}}<span v-if="person.title">, {{person.title}}</span>
      </Headline>

      <BlueBox headline="CV" :img="require(`@/assets/csslab-web-content/data/employees/${person.img}`)">
        <p>
          {{person.cv}}
        <a class ="link" v-if="hasLink" :href ="person.link" target="_blank">{{ person.link_text }}
        </a>
        </p>
        <ul class="contact-info">
         <p>
           <span v-if="hasEmail"> Email:
             <a :href="'mailto:'+person.email">{{person.email}}</a> <br/>
           </span>
            <span v-if="hasTel"> Tel:
             <a :href="'tel:'+person.tel">{{person.tel}}</a>
           </span>
         </p>
        </ul>
      </BlueBox>

      <ContentBox headline="Research Interests" v-if="hasResearchInterests">
          {{person.researchInterests}}
      </ContentBox>

      <ContentBox headline="Projects" v-if="hasProjects">
        <ul class="projectlist arrow-style" v-if="hasProjects">
          <li v-for="project in person.projects" :key="project.name">
            <p class="projectName">{{project.name}}</p>
            <p class="projectDescription"><span v-html="project.description"/></p>
          </li>
        </ul>
        <p v-else>
          <span v-if="person.ref==='gramelsberger'">See
            <router-link :to="{ name: 'projects'}">Projects.</router-link></span>
          <span v-else>No projects at this time.</span>
        </p>
      </ContentBox>

      <ContentBox headline="Lectures and Seminars" v-if="hasSeminars">
        <ul class="arrow-style" v-if="hasSeminars">
          <li v-for="field in seminarFields" :key="field">
            {{field}}
            <ul class="seminar-list-inner">
              <p v-if="seminarModulesIn(field).winter.length > 0">Winter term
                <ul class="seminar-list-inner">
                  <li v-for="seminar in seminarModulesIn(field).winter"
                  :key="seminar.name+seminar.term">
                  {{seminar.type}} <b>{{seminar.name}}</b> <div v-if="seminar.description"> ({{seminar.description}}) </div>
                 </li>
                </ul>
              </p>
              <p v-if="seminarModulesIn(field).summer.length > 0">Summer term
                <ul class="seminar-list-inner">
                  <li v-for="seminar in seminarModulesIn(field).summer"
                  :key="seminar.name+seminar.term">
                  {{seminar.type}} <b>{{seminar.name}}</b> <div v-if="seminar.description"> ({{seminar.description}}) </div>
                 </li>
                </ul>
              </p>
              <p v-if="seminarModulesIn(field).noTerm.length > 0">
                <ul class="seminar-list-inner">
                  <li v-for="seminar in seminarModulesIn(field).noTerm"
                  :key="seminar.name+seminar.term">
                  {{seminar.type}} <b>{{seminar.name}}</b> <div v-if="seminar.description"> ({{seminar.description}}) </div>
                 </li>
                </ul>
              </p>
            </ul>
          </li>
        </ul>
        <!-- <p v-else>No seminars at this time.</p> -->
      </ContentBox>

      <ContentBox v-bind:headline="this.isSelection">
        <div v-if="hasPublications">
          <PublicationList headline="Books, Edited Volumes and Special Issues"
            :over="getPublications.books" v-if="hasBooks"/>

            <PublicationList headline="Peer-reviewed Articles"
            :over="getPublications.reviewed" v-if="hasReviewed"/>

            <PublicationList headline="Book and Journal Contributions"
            :over="getPublications.contributions" v-if="hasContributions"/>

            <PublicationList headline="Reviews"
            :over="getPublications.reviews" v-if="hasReviews"/>

            <PublicationList headline="Dissertation"
            :over="getPublications.dissertation" v-if="hasDissertation"/>

            <PublicationList headline="Scientific Reports"
            :over="getPublications.reports" v-if="hasReports"/>

            <PublicationList headline="Popular Journals"
            :over="getPublications.popularJournals" v-if="hasPopularJournals"/>

            <PublicationList headline="Miscellaneous"
            :over="getPublications.misc" v-if="hasMisc"/>
        </div>
        <p v-else>No publications yet.</p>
      </ContentBox>


    </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';
import PublicationList from '@/components/PublicationList.vue';

export default {
  name: 'PersonPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    Headline,
    ContentBox,
    BlueBox,
    PublicationList,
  },
  computed: {
    isSelection() {
      if(this.person.selection) {
        return "Publications (Selection)"
      } else {
        return "Publications"
      }
    },
    person() {
      let person;
      try{
        person = this.$store.state.data[this.$route.path.toLowerCase().substring(6)];
      }
      catch(error) {
        console.error(error);
      }
      return person;
    },
    hasLink(){
      return (this.person.link !== null);
    },
    hasProjects() {
      if (this.person.projects !== undefined && this.person.projects != null) {
        if (this.person.projects.length > 0) {
          return true;
        }
      }
      return false;
    },
    hasResearchInterests() {
      if (this.person.researchInterests !== undefined && this.person.researchInterests != null) {
        if (this.person.researchInterests.length > 0) {
          return true;
        }
      }
      return false;
    },
    hasSeminars() {
      if (this.person.seminars !== undefined && this.person.seminars != null) {
        if (this.person.seminars.length > 0) {
          return true;
        }
      }
      return false;
    },
    hasPublications() {
      if (this.person.publications !== undefined && this.person.publications != null) {
        if (Object.values(this.person.publications).some(element => element.length > 0)) {
          /* Check if any "child array" of person.publications (books, reviews etc.) has length > 0.
            Object.values: array of all "child arrays" of person.publications: [books, reviews ...]
            some(): true if any element of the array fulfills callback
            callback: check if element has length >0
          */
          return true;
        }
      }
      return false;
    },
    hasBooks() {
      if (!this.hasPublications) return false;
      if (this.person.publications.books !== undefined && this.person.publications.books != null) {
        if (this.person.publications.books.length > 0) return true;
      }
      return false;
    },
    hasContributions() {
      if (!this.hasPublications) return false;
      if (this.person.publications.contributions !== undefined
        && this.person.publications.contributions != null) {
        if (this.person.publications.contributions.length > 0) return true;
      }
      return false;
    },
    hasReviewed() {
      if (!this.hasPublications) return false;
      if (this.person.publications.reviewed !== undefined
        && this.person.publications.reviewed != null) {
        if (this.person.publications.reviewed.length > 0) return true;
      }
      return false;
    },
    hasReviews() {
      if (!this.hasPublications) return false;
      if (this.person.publications.reviews !== undefined
        && this.person.publications.reviews != null) {
        if (this.person.publications.reviews.length > 0) return true;
      }
      return false;
    },
    hasDissertation() {
      if (!this.hasPublications) return false;
      if (this.person.publications.dissertation !== undefined
        && this.person.publications.dissertation != null) {
        if (this.person.publications.dissertation.length > 0) return true;
      }
      return false;
    },
    hasReports() {
      if (!this.hasPublications) return false;
      if (this.person.publications.reports !== undefined
        && this.person.publications.reports != null) {
        if (this.person.publications.reports.length > 0) return true;
      }
      return false;
    },
    hasPopularJournals() {
      if (!this.hasPublications) return false;
      if (this.person.publications.popularJournals !== undefined
        && this.person.publications.popularJournals != null) {
        if (this.person.publications.popularJournals.length > 0) return true;
      }
      return false;
    },
    hasMisc() {
      if (!this.hasPublications) return false;
      if (this.person.publications.misc !== undefined && this.person.publications.misc != null) {
        if (this.person.publications.misc.length > 0) return true;
      }
      return false;
    },
    hasEmail() {
      if (this.person.email != null && this.person.email !== undefined && this.person.email !== '') {
        return true;
      }
      return false;
    },
    hasTel() {
      if (this.person.tel != null && this.person.tel !== undefined && this.person.tel !== '') {
        return true;
      }
      return false;
    },
    seminarFields() {
      const res = [];
      this.person.seminars.forEach((seminar) => {
        if (!res.includes(seminar.in)) res.push(seminar.in);
      });
      return res;
    },
    getPublications() {
      let publications = {};
      let person = this.$store.state.data[this.$route.path.toLowerCase().substring(6)];

      for(const format in person.publications)  {
        publications[format] = person.publications[format].sort((a, b) => (a.year < b.year) ? 1 : -1);
      }

      return publications;
    }

  },
  methods: {
    seminarModulesIn(field) {
      const winter = this.person.seminars.filter(seminar => seminar.in === field
        && seminar.term.toLowerCase().includes('winter'));
      const summer = this.person.seminars.filter(seminar => seminar.in === field
        && seminar.term.toLowerCase().includes('summer'));
      const noTerm = this.person.seminars.filter(seminar => seminar.in === field
        && seminar.term === '');
      return { winter, summer, noTerm };
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/gramelsberger.json",
      dir: "gramelsberger"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/wenz.json",
      dir: "wenz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kasprowicz.json",
      dir: "kasprowicz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kraemer.json",
      dir: "kraemer"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schuettler.json",
      dir: "schuettler"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/boehres.json",
      dir: "boehres"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kerksieck.json",
      dir: "kerksieck"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/heyen.json",
      dir: "heyen"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schaffrath.json",
      dir: "schaffrath"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/scheeren.json",
      dir: "scheeren"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/dumschat.json",
      dir: "dumschat"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/maschmann.json",
      dir: "maschmann"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kaminski.json",
      dir: "kaminski"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/pantsar.json",
      dir: "pantsar"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/venator.json",
      dir: "venator"
    });
  },
};
</script>

<style scoped>

.person {
  padding-bottom: 50px;
}

.textblockRow {
  margin-left: 0px!important;
  margin-right: 0px!important;
}

.personblock-global {
  background-color: rgb(0, 84, 159);
  -webkit-box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  margin: 0 auto; /*fix this!!*/
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center; /*to center image vertically*/
}

.personText {
  float: right;
}

.personblock-global h3{
  color: white;
  padding-top: 20px;
  font-weight: lighter;

}

.personblock-global p{
  color: white;
  padding-bottom: 20px;

}

.personImageWrapper {
  float: left;
  margin-right: 20px;
  margin-top: 20px; /*use if image is not centered vertically*/
  margin-bottom: 20px; /*use if image is not centered vertically*/
  width: 250px;
  min-height: 220px;
}

.personImage {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 992px) {
  .personblock-global {
    flex-direction: column;
  }
}

.listblock-global {
  background-color: white;
  -webkit-box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  margin: 0 auto; /*fix this!!*/
}

.listblock-global h3{
  color: rgb(0, 84, 159);
  padding-top: 20px;
  font-weight: lighter;

}

.textblockRow {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  background-color: whitesmoke;
}

.projectlist {
  padding-bottom: 20px;
}

.projectlist li{
  margin-bottom: -10px;
}

.publication-type-headline {
  color: rgb(0, 84, 159);
  font-weight: lighter;
  margin-bottom: 0px;
}

.projectName {
  color: rgb(0, 84, 159);
  margin-bottom: 5px;
}

.arrow-style p {
  margin-bottom: 0px;
}

.seminar-list-inner {
  list-style: none;
  padding-left: 20px;
}

.seminar-list-inner li:before {
  content: none;
}

/* arrow-style globally defined in App.vue */

.contact-info a{
  color: #BDCD00;
}

.link{
  color: #BDCD00;
}
</style>
