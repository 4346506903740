<template>
  <div class="team-overview">
    <Banner/>

    <Headline>
      Alumni
    </Headline>

    <ContentBox headline="Guest Professor">
      <ul class="arrow-style">
        <li v-for="person in guestProfessorAlumni" :key="person.name"><b>
          <span v-if="person.link"><a target="_blank" href="https://www.philosophie.tu-darmstadt.de/institut_phil/mitarbeiter_innen_phil/kaminski.en.jsp" > {{ person.name }}</a></span>
          <span v-else={{person.name}}></span>
          <span v-if="person.title">, {{person.title}}</span></b> - {{person.field}}
          <p>{{person.cv}}</p>
        </li>
      </ul>
    </ContentBox>

    <ContentBox headline="Research Assistants">
      <ul class="arrow-style">
        <li v-for="person in researchAlumni" :key="person.name"><b>{{person.name}}
          <span v-if="person.title">, {{person.title}}</span></b> - {{person.field}}
          <p>{{person.cv}}</p>
        </li>
      </ul>
    </ContentBox>

    <ContentBox headline="Student Research Assistants">
      <ul class="arrow-style">
        <li v-for="person in studentAlumni" :key="person.name"><b>{{person.name}}
          <span v-if="person.title">, {{person.title}}</span></b> - {{person.field}}
          <p>{{person.cv}}</p>
        </li>
      </ul>
    </ContentBox>

  </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';
import Banner from '@/components/Banner.vue';

import gramelsberger from '@/assets/csslab-web-content/data/employees/gramelsberger.json';
import wenz from '@/assets/csslab-web-content/data/employees/wenz.json';
import kasprowicz from '@/assets/csslab-web-content/data/employees/kasprowicz.json';
import kraemer from '@/assets/csslab-web-content/data/employees/kraemer.json';
import schuettler from '@/assets/csslab-web-content/data/employees/schuettler.json';
import boehres from '@/assets/csslab-web-content/data/employees/boehres.json';
import kerksieck from '@/assets/csslab-web-content/data/employees/kerksieck.json';
import heyen from '@/assets/csslab-web-content/data/employees/heyen.json';
import schaffrath from '@/assets/csslab-web-content/data/employees/schaffrath.json';
import scheeren from '@/assets/csslab-web-content/data/employees/scheeren.json';
import dumschat from '@/assets/csslab-web-content/data/employees/dumschat.json';
import maschmann from '@/assets/csslab-web-content/data/employees/maschmann.json';
import mkaminski from '@/assets/csslab-web-content/data/employees/kaminski.json';
import PersonPageVue from './PersonPage.vue';

export default {
  name: 'AlumniPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
    Banner,
  },
  computed: {
    getEmployees: function() {
      let employees = [
        this.$store.state.data.gramelsberger,
        this.$store.state.data.wenz,
        this.$store.state.data.kasprowicz,
        this.$store.state.data.kraemer,
        this.$store.state.data.schuettler,
        this.$store.state.data.boehres,
        this.$store.state.data.kerksieck,
        this.$store.state.data.heyen,
        this.$store.state.data.schaffrath,
        this.$store.state.data.scheeren,
        this.$store.state.data.dumschat,
        this.$store.state.data.kaminski,
        this.$store.state.data.maschmann
      ]
      return employees;
    },
    guestProfessorAlumni() {
      return this.getEmployees.filter(person => String(person.status).toLowerCase().includes('guest professor')
        && String(person.status).toLowerCase().includes('alumni'));
    },
    studentAlumni() {
      return this.getEmployees.filter(person => String(person.status).toLowerCase().includes('student assistant')
        && String(person.status).toLowerCase().includes('alumni'));
    },
    researchAlumni() {
      return this.getEmployees.filter(person => String(person.status).toLowerCase().includes('research assistant')
       && String(person.status).toLowerCase().includes('alumni'));
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/gramelsberger.json",
      dir: "gramelsberger"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/wenz.json",
      dir: "wenz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kasprowicz.json",
      dir: "kasprowicz"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kraemer.json",
      dir: "kraemer"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schuettler.json",
      dir: "schuettler"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/boehres.json",
      dir: "boehres"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kerksieck.json",
      dir: "kerksieck"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/heyen.json",
      dir: "heyen"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/schaffrath.json",
      dir: "schaffrath"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/scheeren.json",
      dir: "scheeren"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/dumschat.json",
      dir: "dumschat"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/kaminski.json",
      dir: "kaminski"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/employees/maschmann.json",
      dir: "maschmann"
    });
  },
};
</script>

<style scoped>
.team-overview {
  background-color: whitesmoke;
  padding-bottom: 50px;
}

.person-image {
  display: inline-block;
  float: right;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 650px) {
  .person-image {
    float: initial;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
span, p {
  white-space: pre-wrap;
}
</style>
