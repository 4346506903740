<template>
    <b-row class="content-row" :style="style">
      <b-col cols="11" lg="8" class="content-box mx-auto">
        <h3>{{headline}}</h3>
        <p>
          <slot></slot>
        </p>
      </b-col>
    </b-row>
</template>

<script>
export default {
  name: 'ContentBox',
  props: {
    headline: String,
    color: {
      default: 'white',
      type: String,
    },
  },
  computed: {
    style() {
      return `background-color: ${this.color};`;
    },
  },
};
</script>

<style scoped>

.content-row {
  /*avoid white gap on the right side of the page when using container and rows. */
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.content-box h3{
  color: rgb(0, 84, 159);
  padding-top: 15px;
  padding-bottom: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
  font-weight: lighter;
}

.content-box h3:after {
  content:"";
  position: absolute;
  border-bottom:1px solid rgb(0, 84, 159);
  width:100%;
  height:0.7em;
  display: inline;
  margin-left: 0.5em;
}

.content-box p {
  hyphens: auto;
}
</style>
