<template>
    <div>
      <h4 class="publication-type-headline" v-if="headline">{{headline}}</h4>
      <ul class="projectlist arrow-style">
        <li v-for="publication in specialIssues" :key="publication.name">
          <p>
            <span v-if="publication.authors">({{ publication.authors }}) </span>
            <span v-if="publication.year">({{ publication.year }}) </span>
            <b>{{publication.name}}. </b>
            <span v-if="publication.issue"> ({{publication.issue}}) </span>
            <span v-if="publication.in"> ({{publication.in}}) </span>
            <span v-if="publication.city"> {{publication.city}}: </span>
            <span v-if="publication.publisher"> {{publication.publisher}}.</span>
            <span v-if="publication.volume"> {{publication.volume}}.</span>
            <span v-if="publication.in_print"> (in print)</span>
            <span v-if="publication.url">
              <a :href="publication.url" target="_blank" rel="noopener">
                open access
              </a></span>
          </p>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  const specialIssues = require('@/assets/csslab-web-content/data/publications/specials.json');
  
  export default {
    name: 'SpecialIssuesList',
    props: {
      headline: {
        type: String,
        default: '',
      },
      over: Array,
    },
    computed: {
      specialIssues() {
        return specialIssues;
      },
    },
  };
  </script>
  
  <style scoped>
  .projectlist {
    padding-bottom: 20px;
    text-align:left;
  }
  .projectlist li{
    margin-bottom: -10px;
  }
  .publication-type-headline {
    color: rgb(0, 84, 159);
    font-weight: lighter;
    margin-bottom: 0px;
  }
  .arrow-style p {
    margin-bottom: 0px;
  }
  </style>
  