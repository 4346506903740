<template>
    <div class="generic">
      <Headline>{{ teachingLecturers.headLine }}</Headline>

      <ContentBox headline="Lecturers">
        <ul class="arrow-style">
          <li v-for="entry in teachingLecturers.content.usualLecturers" :key="entry.name">
            <p>
              <b>{{entry.name}}</b>
            </p>
            <p>
              <i>{{entry.topics}}.</i>
            </p>
          </li>
        </ul>
      </ContentBox>

      <ContentBox headline="Associate Lecturers">
        <ul class="arrow-style">
          <li v-for="entry in teachingLecturers.content.associateLecturers" :key="entry.name">
            <p>
              <b>{{entry.name}}</b>
              <br>
              <i>{{entry.topics}}.</i>
            </p>
          </li>
        </ul>
      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'PhilosophyLecturersPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    BlueBox,
    Headline,
  },
  computed: {
    teachingLecturers: function() {
      return this.$store.state.data.teachingLecturers;
    }
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/teachingLecturers.json",
      dir: "teachingLecturers"
    });
  },
};
</script>

<style scoped>
.generic {
  padding-bottom: 50px;
}
.event-row {
  margin-left: 0px;
}
.event-name-column {
  padding-bottom: 5px;
  padding-left: 0px;
}
.event-type-column {
  padding-right: 0px;
}
.event-type-column:before {
  content: "";
  border-color: transparent rgb(0, 84, 159);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: inline-block;
  left: -0.5em;
  position: relative;
}
@media screen and (max-width: 768px) {
  .event-name-column {
    padding-bottom: 15px;
    margin-left: 1.5em;
  }
}
</style>
